
<template>
   <div>
      <div class="flex items-center w-full p-4 mb-base bg-primary text-white top-header-page" @click.stop="back()">
         <feather-icon icon="ChevronLeftIcon"  class="cursor-pointer mr-4" @click.stop="back()"></feather-icon>
         <h4 class="text-white">{{ $route.params.item.task_title }}</h4>
      </div>
      <vx-card title-color="primary" class="scroll-content mb-base">
         <ul class="bordered-items">
            <h5 class="mb-2">Persyaratan</h5>
            <li v-for="(syarat, indexSyarat) in $route.params.item.relation.persyaratan" :key="indexSyarat" class="py-2 flex">
               <span class="mr-4">{{ indexSyarat+1 }}. </span>
               <span>{{ syarat.name }}</span>
            </li>
         </ul>
         <ul class="bordered-items mt-base">
            <h5 class="mb-2">Penyelesaian</h5>
            <li class="py-2 flex">
               <span class="mr-4">{{ $route.params.item.waktu_penyelesaian_jumlah }} Hari Kerja </span>
            </li>
         </ul>
         <ul class="bordered-items mt-base">
            <h5 class="mb-2">Keterangan</h5>
            <li v-for="(keterangan, indexSyarat) in $route.params.item.relation.keterangan" :key="indexSyarat" class="py-2 flex">
               <span class="mr-4">{{ indexSyarat+1 }}. </span>
               <span>{{ keterangan.name }}</span>
            </li>
         </ul>
         <ul class="bordered-items mt-base">
            <h5 class="mb-2">Tarif</h5>
            <li class="py-2 flex">
               <span class="mr-4">{{ $route.params.item.tarif }}</span>
            </li>
         </ul>
         <ul class="bordered-items mt-base">
            <h5 class="mb-2">Lampiran <span style="color:red;"> *wajib</span></h5>
            <li v-for="(lampiran, indexLampiran) in $route.params.item.relation.lampiran" :key="indexLampiran" class="py-2 flex items-center justify-between">
               <span>{{ lampiran.name }}</span>

               <vs-button color="danger" type="relief" size="small" icon-pack="feather" icon="icon-download" class="mr-5" :href="lampiran.link" target="blank">link
               </vs-button>
               <!--@click.prevent="downloadItem(lampiran)"-->
            </li>
         </ul>
      </vx-card>
      <!--
      <vs-button color="success" type="relief" class="w-full mt-5" @click.stop="toData()">
         <h5 class="text-white">JADWALKAN KE ATR / BPN</h5>
      </vs-button>
      -->
   </div>
</template>

<script>
import { mapGetters } from 'vuex'
import themeConfig from "@/../themeConfig.js"

export default {
   data () {
      return {
      }
   },
   components: {
   },
   computed: {
      ...mapGetters({
         user : 'auth/user',
      })
   },
   created () {
      this.$store.commit('navbarUpdateStatus', true)
   },
   methods: {
      downloadItem (item) {
         /*
         const url = '/download-file/'+item.file
         const url = this.getPDF('/'+item.file)
         
         let configs = {
            headers: {
               'Authorization': 'Bearer ' + this.user.api_token,
            },
         }
         this.axios.get(url, { responseType: 'blob' }, configs)
         .then(response => {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = item.name
            link.click()
            URL.revokeObjectURL(link.href)
         }).catch(console.error)
         */
         //if (this.user.api_token) {
         /*
            this.axios({
               url: '/download-file/file/'+item.file,
               method: 'GET',
               responseType: 'arraybuffer',
               //headers: { 'Authorization': 'Bearer ' + this.user.api_token }
            }).then((response) => {
               let blob = new Blob([response.data], {type: 'application/pdf'})
               
               if (themeConfig.mobileMode === true) {
                  //var fileTransfer = new FileTransfer();
                  //navigator.fileTransfer.download(blob, item.name+'.pdf')
                  //FileTransfer.download(blob, item.name+'.pdf')
                  const filename = item.name+'.pdf'
                  this.saveBlobToSystem(filename, blob)
               } else {
                  let link = document.createElement('a')
                  link.href = window.URL.createObjectURL(blob)
                  link.download = item.name+'.pdf'
                  link.click()
               }
            });
         */
        
         this.axios({
            url: '/download-file/file/'+item.file,
            method: 'GET',
            responseType: 'blob',
         })
         .then((response) => {
            
            const blob = new Blob([response.data], {type: response.data.type})
            let fileName = item.name+'.pdf'
            /*
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
            window.URL.revokeObjectURL(url)
            */
            if (themeConfig.mobileMode === true) {
               this.saveBlobToSystem(fileName, blob)
               //this.saveBlobToSystem2(fileName, blob)
               //this.saveBlobToSystem4(fileName, blob)
               //this.writeFile(blob)
            }

         })
         
         /*
         } else {
            this.$vs.notify({
               title: 'Maaf',
               color  : 'danger',
               text  : 'Anda harus login untuk men-download file',
          })
         }
         */
         
        /*
         this.axios({ 
            method: 'get', 
            url, 
            responseType: 'arraybuffer', 
            headers: { 'Authorization': 'Bearer ' + this.user.api_token }
         })
         .then((response) => {
            this.forceFileDownload(response, item.name)
         })
         .catch(() => alert('error occured'))
         */
      },
      saveBlobToSystem(filename, blob) {

         //let folderpath = "file:///storage/emulated/0/Download/";
         //let folderpath = "file:///storage/emulated/0/Documents/";
         let folderpath = window.cordova.file.externalDataDirectory;
         //let folderpath = window.cordova.file.internalDataDirectory;

         //let vm = this;
         const onError = function() {
         alert("Error saving File to System");
         };
         const onError1 = function() {
         alert("Error saving File to System 1");
         };
         const onError2 = function() {
         alert("Error saving File to System 2");
         };

         window.resolveLocalFileSystemURL(folderpath, function(dir) {
         //window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, function (dir) {
         //window.resolveLocalFileSystemURL(navigator.file.dataDirectory, function (dir) {
            alert("Access to the directory granted succesfully");
            dir.getFile(filename, { create: true }, function(file) {
               alert("File created succesfully.");
               file.createWriter(function(fileWriter) {
                  alert("Writing content to file");
                  fileWriter.write(blob);
                  alert("Successfully write file to system");
               }, onError2);
            }, onError1);
         }, onError);
      },
      
      saveBlobToSystem2(filename, blob) {
         let folderPath = window.cordova.file.externalDataDirectory;
         window.resolveLocalFileSystemURL(
         folderPath,
         function(dir) {
            dir.getFile(filename, { create: true },
               function(file) {
               file.createWriter(
                  function(fileWriter) {
                     fileWriter.write(blob);
                     fileWriter.onerror = function(err) {
                     alert("Unable to download" + err);
                     };
                  },
                  function(err) {
                     // failed
                     alert("Unable to download" + err);
                  }
               );
               },
               function(err) {
               alert("Unable to download" + err);
               }
            );
         },
         function(err) {
            alert("Unable to download" + err);
         }
         );
      },
      
      saveBlobToSystem3(filename, blob) {
         window.requestFileSystem(window.TEMPORARY, 5 * 1024 * 1024, function (fs) {
            
         this.saveFile(fs.root, blob, filename);

         });
      },

      saveFile(dirEntry, fileData, fileName) {

         dirEntry.getFile(fileName, { create: true, exclusive: false }, function (fileEntry) {

            this.writeFile(fileEntry, fileData);

         });
      },
      
      writeFile(fileEntry, dataObj) {
         fileEntry.createWriter(function (fileWriter) {
            fileWriter.write(dataObj);
         });
      },
      
      saveBlobToSystem4(filename, blob) {
         window.requestFileSystem(window.TEMPORARY, 5 * 1024 * 1024, function (fs) {
            
            //this.saveFile(fs.root, blob, filename);
            fs.root.getFile(filename, { create: true, exclusive: false }, function (fileEntry) {
               fileEntry.createWriter(function (fileWriter) {
                  fileWriter.write(blob);
               });
            });

         });
      },
      
      forceFileDownload(response, title) {
         //alert(title)
         const url = window.URL.createObjectURL(new Blob([response.data]))
         const link = document.createElement('a')
         link.href = url
         link.setAttribute('download', title)
         document.body.appendChild(link)
         link.click()
      },
      back () {
         let tasks = this.$route.params.layanan
         if (this.$route.params.layanan){
            this.$router.replace({ 
               name: 'Layanan',
               params: { tasks } 
            });
         } else {
            this.$router.replace({ 
               name: 'Home'
            });
         }
      },
      toData () {
         let item = this.$route.params.item
         this.$router.push({
            name: "LayananData",
            params: { item }
         });
         this.$store.commit('navbarUpdateStatus', false)
      }
   }
}
</script>

<style scoped>
</style>
